<template>
  <div id="Activity">
    <template v-if="false"></template>
    <template v-else>
      <div class="activity-carousel">
        <a-carousel :after-change="carouselOnChange" class="">
          <template v-for="(item, key) in activity.item">
            <router-link :to="item.link" :key="key">
              <div class="image-transform">
                <img :src="item.image" :alt="item.alt" />
              </div>
            </router-link>
          </template>
          <!-- <template v-for="i in 4">
            <div class="image" :key="i">
              <img
                :src="`${$public}/images/carousel/carousel-${i - 1}.jpg`"
                alt=""
                fit="cover"
              />
            </div>
          </template> -->
        </a-carousel>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from "vue";
import { Carousel } from "ant-design-vue";
Vue.use(Carousel);

export default {
  data: function () {
    return {
      activity: {
        item: [
          {
            link: { /* name: "Activity-Server-Migration" */}, 
            image: `${this.$public}/images/Activity-Server-Migration.png`,
            alt: "服务器迁移香港",
          },
        ],
      },
    };
  },
  methods: {
    carouselOnChange(a, b, c) {
      console.log(a, b, c);
    },
  },
};
</script>

<style lang="less">
#Activity {
  .activity-carousel {
    position: relative;
    height: 0;
    padding-bottom: 50%;

    > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .slick-slide > div > a > .image-transform {
        padding-bottom: 50%;
      }

      *:not(.slick-dots, .image-transform) {
        height: 100%;
      }
    }
  }
}
</style>